import { useContext } from 'react'
import { TaxRateContext } from '../context/TaxRateContext'

export const useTaxRates = () => {
  const context = useContext(TaxRateContext)
  if (context === undefined) {
    throw new Error('useTaxRates must be used within a TaxRateProvider')
  }
  return context
}
