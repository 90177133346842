/* eslint-disable @typescript-eslint/explicit-function-return-type */
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

import { navigate } from 'gatsby'
import React, { useState } from 'react'
import Loader from 'react-loader-spinner'
import Modal from 'react-modal'

import { withErrorHandler } from '../components/errorHandler'
import FormDefaultImage from '../components/Payment/FormDefaultImage'
import PaymentForm from '../components/Payment/PaymentForm'
import { Section } from '../components/shared'
import * as I from '../components/shared/Icons'
import SubscriptionComponent from '../components/Subscriptions/subscriptions'
import { WORKOUTS_ROUTE } from '../constants/routes'
import { SubscriptionResponse } from '../redux/actions'
import * as session from '../services/session'
import * as S from '../styles/index.styles'
import * as M from '../styles/modal.styles'
import Layout from '../templates/Layout'
import YouTube from 'react-youtube'
import { isMobile } from 'react-device-detect'

const customStyles = {
  overlay: {
    zIndex: 100000,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  content: {
    top: '40%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    border: '0px solid transparent',
  },
}

interface State {
  selectedSubscription: SubscriptionResponse
  isModalOpen: boolean
  isLoadingPayment: boolean
  errorInPayment: boolean
  error: any
}

interface Props {
  defaultOpenPaymentForm?: boolean
  renderWithoutLayout?: boolean
  hideNewsletter?: boolean
}

const defaultState = {
  selectedSubscription: undefined,
  isModalOpen: false,
  isLoadingPayment: true,
  errorInPayment: false,
  error: undefined,
}

const GATSBY_PURCHASE_SCREEN_VIDEO_LINK =
  process.env.GATSBY_PURCHASE_SCREEN_VIDEO_LINK

// eslint-disable-next-line arrow-body-style
const SignUp = (props: Props) => {
  session.redirectIfLoggedIn(WORKOUTS_ROUTE)
  const [state, setState] = useState<State>(defaultState)

  const handleSelection = (sub: SubscriptionResponse) => {
    const { selectedSubscription } = state

    setState({
      ...state,
      selectedSubscription:
        selectedSubscription?.priceId === sub.priceId ? undefined : sub,
    })
  }

  const handleModal = () =>
    setState({
      ...state,
      isModalOpen: true,
    })

  const paymentSuccessful = () =>
    setState({
      ...state,
      isLoadingPayment: false,
    })

  const paymentUnsuccessful = (error: string) =>
    setState({
      ...state,
      errorInPayment: true,
      error,
      isLoadingPayment: false,
    })

  const onCloseModal = () => {
    const { isLoadingPayment, errorInPayment } = state

    if (!isLoadingPayment && !errorInPayment) {
      setState({
        ...state,
        isModalOpen: false,
      })
      navigate(WORKOUTS_ROUTE)
    }

    if (errorInPayment) {
      setState({
        ...state,
        isModalOpen: false,
      })
    }
  }

  const videoOpts = {
    height: isMobile ? 250 : 450,
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  }

  const renderSecondary = () => {
    const {
      selectedSubscription,
      isModalOpen,
      isLoadingPayment,
      errorInPayment,
      error,
    } = state
    const { defaultOpenPaymentForm, renderWithoutLayout } = props

    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <Section
          style={{ paddingTop: '10px', paddingBottom: '10px', height: '100%' }}
        >
          {!renderWithoutLayout && <div style={{ marginTop: '40px' }}></div>}
          <S.ContentWrapper>
            <SubscriptionComponent
              priceIdSelected={selectedSubscription?.priceId}
              handleSelection={handleSelection}
            />

            <S.FormWrap>
              {defaultOpenPaymentForm || !!selectedSubscription?.priceId ? (
                <PaymentForm
                  key={selectedSubscription?.priceId}
                  isVisible={!!selectedSubscription?.priceId}
                  subscription={selectedSubscription}
                  handleModal={handleModal}
                  paymentSuccessful={paymentSuccessful}
                  paymentUnsuccessful={paymentUnsuccessful}
                  hideNewsletter={props.hideNewsletter}
                />
              ) : (
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  {GATSBY_PURCHASE_SCREEN_VIDEO_LINK ? (
                    <YouTube
                      videoId={GATSBY_PURCHASE_SCREEN_VIDEO_LINK}
                      // @ts-ignore
                      opts={videoOpts}
                      onReady={() => ({})}
                    />
                  ) : (
                    <FormDefaultImage />
                  )}
                </div>
              )}
            </S.FormWrap>
          </S.ContentWrapper>
        </Section>
        <Modal
          ariaHideApp={false}
          isOpen={isModalOpen}
          onRequestClose={onCloseModal}
          style={customStyles}
        >
          <M.Container>
            <M.Wrap>
              {isLoadingPayment ? (
                <>
                  <Loader
                    type="ThreeDots"
                    color="#14213d"
                    height={30}
                    width={30}
                  />
                  <M.Primary>
                    Creating your digital subscription . . .
                  </M.Primary>
                  <M.Secondary>Please wait a few minutes!</M.Secondary>
                </>
              ) : errorInPayment ? (
                <>
                  <M.Primary>An error occurred.</M.Primary>
                  <M.Secondary>{error}</M.Secondary>
                </>
              ) : (
                <>
                  <I.CircleCheck color={'green'} />
                  <M.Primary>
                    Congrats, you&apos;ve successfully started your free trial!
                  </M.Primary>
                  <M.Secondary>Get started on your journey!</M.Secondary>
                </>
              )}
            </M.Wrap>
            {!isLoadingPayment && !errorInPayment && (
              <M.LoginButton onClick={() => navigate(WORKOUTS_ROUTE)}>
                <M.LoginText>Continue to explore workouts</M.LoginText>
              </M.LoginButton>
            )}
          </M.Container>
        </Modal>
      </div>
    )
  }

  return props.renderWithoutLayout ? (
    renderSecondary()
  ) : (
    <Layout>{renderSecondary()}</Layout>
  )
}

export default withErrorHandler(SignUp)
