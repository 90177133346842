import styled from 'styled-components'
import {
  BACKGROUND,
  SUBSCRIPTION_OUTLINE,
  SUBSCRIPTION_FILL,
  SUBSCRIPTION_TEXT,
  SUBSCRIPTION_TEXT_IS_SELECTED,
  SUBSCRIPTION_HEADER,
} from '../constants/colors'
import { maxWidth, TABLET, PHONE } from '../constants/measurements'

export const Subscriptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 24px;
`

export const SubscriptionsMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

interface SubscriptionProps {
  isSelected: boolean
}

export const SubscriptionContainerMobile = styled.div<SubscriptionProps>`
  width: 350px;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: 1px solid
    ${({ isSelected }) =>
      isSelected ? SUBSCRIPTION_FILL : SUBSCRIPTION_OUTLINE};
  border-radius: 15px;
  background-color: ${({ isSelected }) =>
    isSelected ? SUBSCRIPTION_FILL : 'transparent'};
  margin-bottom: 18px;
`

export const SubsriptionDetailMobile = styled.div`
  display: flex;
  flex: 3;
  justify-content: center;
  align-items: center;
`

export const SubscriptionContainer = styled.div<SubscriptionProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 2px solid
    ${({ isSelected }) =>
      isSelected ? SUBSCRIPTION_FILL : SUBSCRIPTION_OUTLINE};
  border-radius: 15px;
  width: 225px;
  padding: 20px 16px;
  background-color: ${({ isSelected }) =>
    isSelected ? SUBSCRIPTION_FILL : 'transparent'};
`

export const Type = styled.div<SubscriptionProps>`
  font-size: 24px;
  line-height: 30px;
  color: ${({ isSelected }) =>
    isSelected ? SUBSCRIPTION_TEXT_IS_SELECTED : SUBSCRIPTION_TEXT};
  letter-spacing: 1px;
  text-transform: capitalize;
`

export const Price = styled.div<SubscriptionProps>`
  font-size: 24px;
  line-height: 30px;
  color: ${({ isSelected }) =>
    isSelected ? SUBSCRIPTION_TEXT_IS_SELECTED : SUBSCRIPTION_TEXT};
  letter-spacing: 0.5px;
  font-weight: 500;
`

export const Discount = styled.div<SubscriptionProps>`
  font-size: 18px;
  height: 26px;
  color: ${({ isSelected }) =>
    isSelected ? SUBSCRIPTION_TEXT_IS_SELECTED : SUBSCRIPTION_TEXT};
  letter-spacing: 0.5px;
  margin-bottom: 36px;
`

export const Details = styled.div<SubscriptionProps>`
  font-size: 17px;
  color: ${({ isSelected }) =>
    isSelected ? SUBSCRIPTION_TEXT_IS_SELECTED : SUBSCRIPTION_TEXT};
  letter-spacing: 0.5px;
  margin-bottom: 15px;
  text-transform: capitalize;
`

export const SignUpButton = styled.div<SubscriptionProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1.75px solid
    ${({ isSelected }) =>
      isSelected ? SUBSCRIPTION_TEXT_IS_SELECTED : SUBSCRIPTION_TEXT};
  height: 35px;
  max-width: 100px;
  cursor: pointer;
  width: 90%;

  &:hover {
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0.7)};
  }
  &:active {
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0.5)};
  }
`

export const SignUpText = styled.div<SubscriptionProps>`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
  color: ${({ isSelected }) =>
    isSelected ? SUBSCRIPTION_TEXT_IS_SELECTED : SUBSCRIPTION_TEXT};
`

export const SubscriptionHeader = styled.div`
  font-size: 30px;
  line-height: 40px;
  color: ${SUBSCRIPTION_HEADER};
  letter-spacing: 1px;
  @media (max-width: ${PHONE}) {
    text-align: center;
  }
`

export const SubscriptionSecondary = styled.div`
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 23px;
  color: ${SUBSCRIPTION_HEADER};
  margin-bottom: 10px;
  @media (max-width: ${PHONE}) {
    text-align: center;
  }
`

export const ContentWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${BACKGROUND};
  height: 100%;

  ${maxWidth(TABLET)} {
    flex-direction: column;
    justify-content: center;
  }
`

export const SubscriptionWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  top: 0;
  height: 100%;

  ${maxWidth(TABLET)} {
    flex-direction: column;
    justify-content: center;
  }
`

export const SubscriptionImageWrap = styled.div`
  display: flex;
`

export const SubscriptionImage = styled.img`
  object-fit: cover;
  height: 45px;
  width: auto;
`

export const FormWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
