/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react'
import {
  Elements,
  CardElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js'
import {
  loadStripe,
  StripeElements,
  Stripe,
  StripeCardElement,
  PaymentMethod,
  StripeCardElementChangeEvent,
  StripeConstructorOptions,
} from '@stripe/stripe-js'
import { navigate } from 'gatsby'
import 'react-credit-cards/es/styles-compiled.css'

import * as S from './PaymentForm.styles'
import {
  createSubscription,
  SubscriptionType,
  createAccAndBuyWorkout,
} from '../../services/fetch/payments'
import { SubscriptionResponse } from '../../redux/actions'
import * as session from '../../services/session'
import { addToEmailList } from '../../services/fetch/user'
import { DARK_BLUE, PRIMARY } from '../../constants/colors'
import { TERMS, PRIVACY, CLIENT_NAME } from '../../constants/routes'
import { ILiveWorkout } from '../../types'
import { validateEmail } from '../../services/fetch/auth'
import PromoCode, { PromoCode as PromoCodeData } from './PromoCode'
import DisplayPrice from './DisplayPrice'
import { useTaxRates } from '../../hooks/useTaxRates'
import { TaxRateResponse } from '../../context/TaxRateContext'

declare global {
  interface Window {
    fbq: any
  }
}

interface LiveClassPurchase {
  amount: number
  currency: string
  priceId: string
  subscriptionType: SubscriptionType
}

interface Props {
  isVisible: boolean
  subscription?: SubscriptionResponse
  liveClass?: LiveClassPurchase
  elements: StripeElements | null
  stripe: Stripe | null
  handleModal(): void
  paymentSuccessful(): void
  paymentUnsuccessful(error: any): void
  workout?: ILiveWorkout
  isPurchase?: boolean
  hideNewsletter?: boolean
  tax: TaxRateResponse | undefined
}

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: PRIMARY,
      color: PRIMARY,
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: 'black' },
      '::placeholder': { color: '#A2A2A6' },
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
  },
}

const connectedAccount = process.env.GATSBY_STRIPE_CONNECTED_ACCOUNT
const options: StripeConstructorOptions = connectedAccount
  ? {
      stripeAccount: connectedAccount,
    }
  : undefined

const stripeKey = process.env.GATSBY_STRIPE_PK
const stripePromise = loadStripe(stripeKey, options)

const freeTrialDays = process.env.GATSBY_FREE_TRIAL_DAYS

interface State {
  email: string
  password: string
  firstName: string
  lastName: string
  isCardComplete: boolean
  focused: string
  isSubscribed: boolean
  emailValidationFailed: boolean
  promo: PromoCodeData | undefined
}
class PaymentForm extends React.Component<Props, State> {
  state = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    isCardComplete: false,
    focused: undefined,
    isSubscribed: false,
    emailValidationFailed: false,
    promo: undefined,
  }

  canSubmit = () => {
    const {
      email,
      password,
      firstName,
      lastName,
      isCardComplete,
      emailValidationFailed,
    } = this.state
    const { elements, isPurchase, subscription } = this.props

    if (
      email === '' ||
      password === '' ||
      firstName === '' ||
      lastName === '' ||
      !isCardComplete ||
      !elements ||
      emailValidationFailed === true
    ) {
      return false
    }
    if (typeof window !== 'undefined' && !isPurchase) {
      if (window.fbq != null) {
        window.fbq('track', 'Subscribe', {
          value: `${subscription?.amount}`,
          currency: `${subscription?.currency}`,
        })
      }
    }
    return true
  }

  handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    this.setState({
      focused: e.target.name,
    })
  }

  handleInputChange = (e: React.FocusEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value })
  }

  onCardChange = (event: StripeCardElementChangeEvent) => {
    const { complete } = event
    const { isCardComplete } = this.state
    if (complete !== isCardComplete) {
      this.setState({
        isCardComplete: complete,
      })
    }
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const {
      stripe,
      elements,
      handleModal,
      paymentSuccessful,
      paymentUnsuccessful,
      subscription,
      workout,
      isPurchase,
      liveClass,
      tax,
    } = this.props

    const {
      firstName,
      lastName,
      email,
      password,
      isSubscribed,
      promo,
    } = this.state
    handleModal()

    if (!stripe || !elements) {
      // stripe has not loaded yet
      return
    }

    const cardElement = elements.getElement(CardElement)

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement as StripeCardElement,
      billing_details: {
        name: `${firstName} ${lastName}`,
      },
    })

    if (result.error) {
      // show error to customer
      paymentUnsuccessful(
        result.error.message || 'There was a problem creating your account.',
      )
    } else {
      // create customer and subscription
      const paymentMethod = result.paymentMethod as PaymentMethod

      const apiMethod = isPurchase ? createAccAndBuyWorkout : createSubscription
      const id = isPurchase ? (workout.id as number) : undefined

      const promoOptions = promo
        ? {
            promoId: promo.id,
          }
        : {}

      const taxRateOptions = tax
        ? {
            taxId: tax.id,
          }
        : {}

      apiMethod(id, {
        paymentMethodId: paymentMethod.id,
        firstName,
        lastName,
        email,
        password,
        type: isPurchase
          ? liveClass.subscriptionType
          : (subscription.priceMetadata.type as SubscriptionType),
        priceId: isPurchase ? liveClass.priceId : subscription.priceId,
        ...promoOptions,
        ...taxRateOptions,
      })
        .then(({ access_token: accessToken, refresh_token: refreshToken }) => {
          // continue to workouts
          try {
            session.loginWithoutRedirect(accessToken, refreshToken)
            paymentSuccessful()
          } catch (err) {
            console.log('err', err)
            // do something
            paymentUnsuccessful(
              err?.message || 'Something went wrong under the hood.',
            )
          }
        })
        .catch(err => {
          console.log('err', err)
          paymentUnsuccessful(
            err?.message || 'There was a problem creating your account.',
          )
        })
      if (isSubscribed) {
        addToEmailList({
          email,
        })
          .then(() => {
            // email success
          })
          .catch(err => console.log(err))
      }
    }
  }

  validateEmail = async () => {
    const { email } = this.state
    try {
      await validateEmail({ email })
      this.setState({ emailValidationFailed: false })
    } catch (e) {
      this.setState({ emailValidationFailed: true })
    }
  }

  render() {
    const {
      isVisible,
      subscription,
      liveClass,
      isPurchase,
      hideNewsletter,
    } = this.props
    const { isSubscribed, promo } = this.state

    const purchaseData = isPurchase ? liveClass : subscription

    return (
      <S.Container style={{ visibility: isVisible ? 'visible' : 'visible' }}>
        <S.Header>Your Account Information</S.Header>
        <form style={{ marginBottom: 0 }} onSubmit={this.handleSubmit}>
          <S.FormInputDiv>
            <S.FormInput
              type="text"
              name="firstName"
              placeholder="First Name"
              required
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              data-test="reg-firstName"
            />
          </S.FormInputDiv>
          <S.FormInputDiv>
            <S.FormInput
              type="text"
              name="lastName"
              placeholder="Last Name"
              required
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              data-test="reg-lastName"
            />
          </S.FormInputDiv>
          {this.state.emailValidationFailed && (
            <S.Text style={{ color: 'red' }} data-test="regEmailInUse">
              Invalid email or already in use
            </S.Text>
          )}
          <S.FormInputDiv>
            <S.FormInput
              type="email"
              name="email"
              placeholder="Email"
              data-test="reg-email"
              required
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
              onBlur={this.validateEmail}
            />
          </S.FormInputDiv>

          <S.FormInputDiv>
            <S.FormInput
              type="password"
              name="password"
              placeholder="Password"
              data-test="reg-password"
              required
              onChange={this.handleInputChange}
              onFocus={this.handleInputFocus}
            />
          </S.FormInputDiv>
          {!hideNewsletter && (
            <S.CheckBoxDiv
              onClick={() => this.setState({ isSubscribed: !isSubscribed })}
            >
              <S.CheckBox>{isSubscribed ? '\u2713' : ''}</S.CheckBox>
              <S.SecondaryText style={{ paddingLeft: 6, marginTop: 2 }}>
                I agree to receive newsletters and product updates from{' '}
                {CLIENT_NAME}
              </S.SecondaryText>
            </S.CheckBoxDiv>
          )}
          {subscription?.metadata?.discount && (
            <PromoCode
              onSetPromo={(promoData: PromoCodeData) =>
                this.setState({
                  promo: promoData,
                })
              }
            />
          )}

          <S.PrimaryText>PAYMENT DETAILS</S.PrimaryText>

          <S.CardElementDiv>
            <CardElement
              data-test="reg-cardInput"
              options={CARD_OPTIONS as any}
              onChange={this.onCardChange}
            />
          </S.CardElementDiv>

          <DisplayPrice
            amount={purchaseData?.amount}
            currency={purchaseData?.currency}
            promo={promo}
            showInfo={isPurchase === undefined}
            type={
              isPurchase
                ? liveClass?.subscriptionType
                : (subscription?.priceMetadata.type as SubscriptionType)
            }
          />

          <S.PaymentButton
            isActive={this.canSubmit()}
            disabled={!this.canSubmit()}
            data-test="reg-submit"
          >
            <S.PaymentText isActive={this.canSubmit()}>
              {isPurchase
                ? 'Purchase'
                : freeTrialDays === '0'
                ? 'Create Account'
                : 'Start free trial'}
            </S.PaymentText>
          </S.PaymentButton>
        </form>
        <S.Text>
          {isPurchase
            ? 'By purchasing the class'
            : 'By starting your free trial'}{' '}
          and creating an account you agree to our{' '}
          <span
            onClick={() => navigate(TERMS)}
            style={{ cursor: 'pointer', color: DARK_BLUE }}
          >
            Terms of Service
          </span>{' '}
          and{' '}
          <span
            onClick={() => navigate(PRIVACY)}
            style={{ cursor: 'pointer', color: DARK_BLUE }}
          >
            Privacy Policy
          </span>
          .
        </S.Text>
      </S.Container>
    )
  }
}

interface InjectedProps {
  isVisible: boolean
  subscription?: SubscriptionResponse
  liveClass?: LiveClassPurchase
  handleModal(): void
  paymentSuccessful(): void
  paymentUnsuccessful(error: any): void
  workout?: ILiveWorkout
  isPurchase?: boolean
  hideNewsletter?: boolean
}

/**
 * Payment form to be used with either subscriptions or live classes
 * for a user that does not yet have an account.
 * If isPurchase is false, it is assumed the form is for a subscription.
 * Otherwise, it is assumed the form is for a live class.
 * The form will break if isPurchase, subscription, and liveClass are not used correctly
 * @param props
 */
const InjectedPaymentForm = (props: InjectedProps) => {
  const { tax } = useTaxRates()
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <PaymentForm
            elements={elements}
            stripe={stripe}
            tax={tax}
            {...props}
          />
        )}
      </ElementsConsumer>
    </Elements>
  )
}

export default InjectedPaymentForm
