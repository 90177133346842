import React from 'react'
import moment from 'moment'
import { getDisplayPrice } from '../../services/currency-codes'
import { SubscriptionType } from '../../services/fetch/payments'
import * as S from './PaymentForm.styles'
import { PromoCode } from './PromoCode'
import { useTaxRates } from '../../hooks/useTaxRates'

interface Props {
  amount: number
  currency: string
  promo: PromoCode
  showInfo: boolean
  type: SubscriptionType
}

const freeTrialDays = process.env.GATSBY_FREE_TRIAL_DAYS

const cancelByDate = (): string =>
  moment()
    .add(Number(freeTrialDays), 'day')
    .format('MMMM Do, YYYY')

const DisplayPrice = (props: Props) => {
  const { amount, promo, currency, showInfo, type } = props
  const { tax } = useTaxRates()

  if (!amount || !currency) {
    return null
  }

  const displayPrice = getDisplayPrice(amount, currency)

  const taxPrice = (total: number) => {
    const { percentage } = tax
    return Math.round(total * (percentage / 100) * 100) / 100
  }

  const totalPrice = (total: number) => {
    if (!tax) {
      return total
    } else {
      return total + taxPrice(total)
    }
  }

  if (!promo) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <S.PriceBlock>
          {tax && (
            <>
              <S.PriceRow style={{ marginBottom: 2 }}>
                <S.TotalText style={{ fontSize: 18 }}>Subtotal</S.TotalText>
                <S.PriceText style={{ fontSize: 18 }}>
                  {displayPrice}
                </S.PriceText>
              </S.PriceRow>
              <S.PriceRow style={{ marginBottom: 6 }}>
                <S.TotalText style={{ fontSize: 18 }}>Sales Tax:</S.TotalText>
                <S.PriceText style={{ fontSize: 18 }}>
                  {getDisplayPrice(taxPrice(amount), currency)}
                </S.PriceText>
              </S.PriceRow>
            </>
          )}
          <S.PriceRow>
            <S.TotalText>Total:</S.TotalText>
            <S.PriceText>
              {getDisplayPrice(totalPrice(amount), currency)}
            </S.PriceText>
          </S.PriceRow>
        </S.PriceBlock>
        {showInfo && (
          <S.SecondaryText>
            Your subscription will begin at{' '}
            {getDisplayPrice(totalPrice(amount), currency)} {currency || ''}{' '}
            (plus any tax) on {cancelByDate()}. Thereafter, your subscription
            will automatically renew each{' '}
            {type === SubscriptionType.annual ? 'year' : 'month'} until
            cancelled.
          </S.SecondaryText>
        )}
      </div>
    )
  }

  const { isAmount } = promo
  let newAmount: number
  let discount: number
  if (isAmount) {
    discount = promo.amountOff
    newAmount = amount - promo.amountOff
  } else {
    discount = Math.round(amount * (promo.percentOff / 100) * 100) / 100
    newAmount = Math.round(amount * ((100 - promo.percentOff) / 100))
  }

  return (
    <>
      <S.PriceBlock>
        <S.PriceRow style={{ marginBottom: 2 }}>
          <S.TotalText style={{ fontSize: 18 }}>Subtotal</S.TotalText>
          <S.PriceText style={{ fontSize: 18 }}>{displayPrice}</S.PriceText>
        </S.PriceRow>
        <S.PriceRow style={{ marginBottom: 2 }}>
          <S.TotalText style={{ fontSize: 18 }}>Discount</S.TotalText>
          <S.PriceText style={{ fontSize: 18 }}>
            {'- '}
            {getDisplayPrice(discount, currency)}
          </S.PriceText>
        </S.PriceRow>
        {tax && (
          <S.PriceRow>
            <S.TotalText style={{ fontSize: 18 }}>Sales Tax:</S.TotalText>
            <S.PriceText style={{ fontSize: 18 }}>
              {getDisplayPrice(taxPrice(newAmount), currency)}
            </S.PriceText>
          </S.PriceRow>
        )}
        <S.PriceRow style={{ marginTop: 6 }}>
          <S.TotalText>Total:</S.TotalText>
          <S.PriceText>
            {getDisplayPrice(totalPrice(newAmount), currency)}
          </S.PriceText>
        </S.PriceRow>
      </S.PriceBlock>

      {showInfo && (
        <S.SecondaryText>
          Your subscription will begin at{' '}
          {getDisplayPrice(totalPrice(newAmount), currency)} {currency} (plus
          any tax) on {cancelByDate()}. Thereafter, your subscription will
          automatically renew each{' '}
          {type === SubscriptionType.annual ? 'year' : 'month'} until cancelled.
        </S.SecondaryText>
      )}
    </>
  )
}

export default DisplayPrice
