/* eslint-disable camelcase */
import {
  API_CREATE_SUBSCRIPTION,
  API_CREATE_ACC_BUY_WORKOUT,
} from '../apiRoutes'
import request from '../request'
import Stripe from 'stripe'

export enum SubscriptionType {
  monthly = 'monthly',
  annual = 'annual',
}

export interface SubscriptionBody {
  priceId: string
  firstName: string
  lastName: string
  email: string
  password: string
  type: SubscriptionType
  paymentMethodId: string
}

export interface SubscriptionResponse {
  access_token: string
  refresh_token: string
  subscription: Stripe.Subscription
}

export const createSubscription = async (
  workoutId: number | undefined,
  body: SubscriptionBody,
): Promise<SubscriptionResponse> =>
  request(API_CREATE_SUBSCRIPTION, body, {}, true).then(
    res => res as SubscriptionResponse,
  )

export const createAccAndBuyWorkout = async (
  id: number,
  body: SubscriptionBody,
): Promise<SubscriptionResponse> => {
  delete body.type
  return request(API_CREATE_ACC_BUY_WORKOUT(id), body, {}, true).then(
    res => res as SubscriptionResponse,
  )
}
