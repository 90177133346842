import React, { useRef, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'
import * as S from './PaymentForm.styles'
import * as I from '../../components/shared/Icons'
import { GRAY_4, PRIMARY, SECONDARY } from '../../constants/colors'
import { api, Methods } from '../../services/httpService'

const DiscountInput = styled.input<{ isError?: boolean }>`
  border: 1px solid ${PRIMARY};
  border-radius: 6px;
  font-size: 18px;
  text-align: left;
  color: ${PRIMARY};
  height: 50px;
  background-color: transparent;
  flex: 7;
  padding-left: 8px;
  margin-right: 10px;

  ::placeholder {
    color: ${PRIMARY};
    opacity: 0.8;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 2.5px ${SECONDARY};
    border: ${({ isError }) =>
      isError ? '2.5px solid red' : `2.5px solid ${SECONDARY}`};
  }
`

const ApplyButton = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
  border-radius: 6px;
  height: 50px;
  background-color: ${({ isDisabled }) => (isDisabled ? GRAY_4 : SECONDARY)};
  border: 1px solid ${({ isDisabled }) => (isDisabled ? GRAY_4 : SECONDARY)};
  width: 15%;
  font-size: 20px;
  text-align: center;
  color: white;
  cursor: pointer;

  ${({ isDisabled }) =>
    !isDisabled &&
    `
  &:hover {
    background-color: ${SECONDARY};
    opacity: 0.8;
  }
  `}
`

const Coupon = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  height: 40px;
  width: 175px;
  background-color: ${SECONDARY};
  font-size: 18px;
  color: white;
  text-align: left;
  letter-spacing: 1.2px;
`

export interface PromoCode {
  id: string
  code: string
  isAmount: boolean
  amountOff?: number
  percentOff?: number
}

interface State {
  promo: PromoCode | undefined
  error: string
  loading: boolean
}

const defaultState: State = {
  promo: undefined,
  error: undefined,
  loading: false,
}

interface Props {
  onSetPromo: (promo: PromoCode) => void
}

const PromoCode = (props: Props) => {
  const { onSetPromo } = props
  const [discountCode, setDiscountCode] = useState<string>('')
  const [state, setState] = useState<State>(defaultState)
  const inputRef = useRef<HTMLInputElement>()

  const applyDiscount = async () => {
    try {
      setState({ ...state, loading: true })
      const response = await api<PromoCode>(
        Methods.post,
        '/subscription/promo-code',
        { code: discountCode },
        { isBypassAuth: true },
      )
      setState({ loading: false, error: undefined, promo: response })
      setDiscountCode('')
      onSetPromo(response)
    } catch (err) {
      setState({
        ...state,
        loading: false,
        error: 'Enter a valid discount code',
      })
      inputRef.current.focus()
    }
  }

  const removeDiscount = () => {
    onSetPromo(undefined)
    setState({ ...state, promo: undefined })
  }

  return (
    <>
      <div style={{ borderBottom: `1px solid ${GRAY_4}`, marginBottom: 32 }} />
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <DiscountInput
          ref={inputRef}
          isError={!!state.error}
          type="text"
          value={discountCode}
          name="promoCode"
          placeholder="Discount Code"
          onChange={e => setDiscountCode(e.target.value.toUpperCase())}
        />
        <ApplyButton
          isDisabled={discountCode === ''}
          onClick={() => applyDiscount()}
        >
          {state.loading ? <CircularProgress size="1.2rem" /> : 'Apply'}
        </ApplyButton>
      </div>
      {state.error && <S.Text style={{ color: 'red' }}>{state.error}</S.Text>}
      {state.promo && (
        <Coupon>
          <div>{state.promo.code}</div>
          <div style={{ cursor: 'pointer' }} onClick={() => removeDiscount()}>
            <I.X fill="white" />
          </div>
        </Coupon>
      )}
      <div
        style={{
          borderBottom: `1px solid ${GRAY_4}`,
          marginTop: 32,
          marginBottom: 24,
        }}
      />
    </>
  )
}

export default PromoCode
