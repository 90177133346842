import styled from 'styled-components'
import { BACKGROUND, GRAY_2, PRIMARY, SECONDARY } from '../../constants/colors'

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  width: 350px;
`

export const Header = styled.div`
  font-size: 24px;
  letter-spacing: 30px;
  margin-bottom: 20px;
  color: ${PRIMARY};
  letter-spacing: 1px;
`

export const SeparatorDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Separator = styled.hr`
  display: flex;
  align-self: center;
  background-color: ${PRIMARY};
  width: 90%;
  height: 1.5;
  margin-bottom: 30px;
`

export const FormInputDiv = styled.div`
  margin-bottom: 16px;
`

export const FormInput = styled.input`
  border: 1px solid ${PRIMARY};
  border-radius: 6px;
  font-size: 18px;
  text-align: left;
  color: ${PRIMARY};
  height: 40px;
  background-color: transparent;
  width: 100%;
  padding-left: 8px;

  ::placeholder {
    color: ${PRIMARY};
    opacity: 0.8;
  }
`

export const CardElementDiv = styled.div`
  border: 1px solid ${PRIMARY};
  padding: 8px;
  border-radius: 6px;
  height: 40px;
`

export const CheckBoxDiv = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  max-width: 300px;
  align-items: flex-start;
`

export const CheckBox = styled.div`
  height: 16px;
  width: 18px;
  border-radius: 3px;
  border: 0.5px solid ${PRIMARY};
  margin-top: 1px;
  background-color: transparent;
  color: ${PRIMARY};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  cursor: pointer;
`

export const FormCheckbox = styled.input`
  font-size: 13px;
`

export const SecondaryText = styled.div`
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  color: ${GRAY_2};
  maxwidth: 300px;
`

export const PrimaryText = styled.div`
  font-size: 20px;
  line-height: 26px;
  color: ${PRIMARY};
  letter-spacing: 1px;
  margin-bottom: 12px;
`

export const PriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0px 33px;
`

export const PriceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`

export const PriceText = styled.div`
  font-size: 22px;
  color: ${PRIMARY};
  letter-spacing: 0.5px;
  text-align: center;
  font-weight: 500;
`

export const TotalText = styled.div`
  font-size: 20px;
  color: ${PRIMARY};
  letter-spacing: 0.5px;
  text-align: center;
`

interface ButtonProps {
  isActive: boolean
}

export const PaymentButton = styled.button<ButtonProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid ${({ isActive }) => (isActive ? BACKGROUND : PRIMARY)};
  height: 40px;
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: ${({ isActive }) => (isActive ? SECONDARY : 'transparent')};
  cursor: pointer;
`

export const PaymentText = styled.div<ButtonProps>`
  font-size: 22px;
  text-align: center;
  letter-spacing: 1px;
  color: ${({ isActive }) => (isActive ? BACKGROUND : PRIMARY)};
`

export const CardDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

export const Text = styled.div`
  max-width: 350px;
  font-size: 16px;
  color: ${PRIMARY};
  letter-spacing: 0.5px;
`
