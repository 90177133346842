import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const FormDefaultImage = () => {
  const data = useStaticQuery(graphql`
    query {
      default: file(id: { eq: "purchase-screen" }) {
        childImageSharp {
          fluid(maxWidth: 3080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const {
    default: {
      childImageSharp: { fluid: defaultFluid },
    },
  } = data

  return <Img fluid={defaultFluid} alt="subscriptions" />
}

export default FormDefaultImage
