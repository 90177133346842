import styled from 'styled-components'
import { DARK_BLUE, GRAY_2, PRIMARY, SECONDARY } from '../constants/colors'

export const Container = styled.div`
  background-color: ${PRIMARY};
  width: 400px;
  height: 300px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Wrap = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Primary = styled.div`
  color: ${DARK_BLUE};
  font-size: 22px;
  margin-top: 40px;
  margin-bottom: 25px;
  text-align: center;
  width: 250px;
  font-weight: 500;
`

export const Secondary = styled.div`
  color: ${GRAY_2};
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.5px;
`

export const LoginButton = styled.div`
  height: 50px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: ${SECONDARY};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const LoginText = styled.div`
  color: ${PRIMARY};
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
`
