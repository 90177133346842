import React, { useEffect } from 'react'
import MediaQuery from 'react-responsive'
import { TABLET_WIDTH } from '../../constants/measurements'
import {
  onFetchSubscriptions as onFetchSubscriptionsAction,
  SubscriptionResponse,
} from '../../redux/actions'
import { DARK_BLUE } from '../../constants/colors'
import * as S from '../../styles/index.styles'
import { HOME_CAPTION, LOGIN_ROUTE } from '../../constants/routes'
import { navigate } from 'gatsby'
import { RootState } from '../../redux/reducers/rootReducers'
import { connect } from 'react-redux'
import { getDisplayPrice } from '../../services/currency-codes'

const Subscriptions = (props: Props) => {
  const {
    subscriptions,
    priceIdSelected,
    handleSelection,
    onFetchSubscriptions,
  } = props

  useEffect(() => {
    onFetchSubscriptions()
  }, [onFetchSubscriptions])

  const getBillingTerm = (type: string): string => {
    if (type.toLowerCase() === 'annual') {
      return '/yr'
    } else if (type.toLowerCase() === 'monthly') {
      return '/mo'
    } else {
      return ''
    }
  }

  const triggerAnalytics = (s: SubscriptionResponse) => {
    typeof (window as any).MyNamespace !== 'undefined' &&
      (window as any).MyNamespace.gtag('Initiate Checkouts', 'click', {
        ...s,
      })
  }

  return (
    <S.SubscriptionWrapper>
      <S.SubscriptionHeader>{HOME_CAPTION}</S.SubscriptionHeader>
      <S.SubscriptionSecondary>
        Already have an account?{' '}
        <span
          onClick={() => navigate(LOGIN_ROUTE)}
          style={{ color: DARK_BLUE, cursor: 'pointer' }}
        >
          Log in
        </span>
      </S.SubscriptionSecondary>
      {subscriptions.length && (
        <MediaQuery maxWidth={TABLET_WIDTH}>
          {matches =>
            matches ? (
              <S.SubscriptionsMobile>
                {subscriptions.map((s: SubscriptionResponse, index) => (
                  <S.SubscriptionContainerMobile
                    key={`${s.priceId}.mobile`}
                    isSelected={s.priceId === priceIdSelected}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingLeft: 15,
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <S.SubsriptionDetailMobile>
                          <S.Type isSelected={s.priceId === priceIdSelected}>
                            {s.priceMetadata.type}
                          </S.Type>
                        </S.SubsriptionDetailMobile>
                        <S.SubsriptionDetailMobile>
                          <S.Price isSelected={s.priceId === priceIdSelected}>
                            {getDisplayPrice(s.amount, s.currency)}
                            {getBillingTerm(s.priceMetadata.type)}
                          </S.Price>
                        </S.SubsriptionDetailMobile>
                      </div>
                      {s.priceMetadata.detail
                        ? `(${s.priceMetadata.detail})`
                        : null}
                    </div>

                    <S.SubsriptionDetailMobile>
                      <S.SignUpButton
                        isSelected={s.priceId === priceIdSelected}
                        onClick={() => {
                          handleSelection(s)
                          triggerAnalytics(s)
                        }}
                        data-test={`selectSubscriptionButton${index}`}
                      >
                        <S.SignUpText
                          isSelected={s.priceId === priceIdSelected}
                        >
                          {s.priceId === priceIdSelected
                            ? 'Selected'
                            : 'Select'}
                        </S.SignUpText>
                      </S.SignUpButton>
                    </S.SubsriptionDetailMobile>
                  </S.SubscriptionContainerMobile>
                ))}
              </S.SubscriptionsMobile>
            ) : (
              <S.Subscriptions>
                {subscriptions.map((s: SubscriptionResponse, index) => (
                  <S.SubscriptionContainer
                    style={{ marginRight: 22 }}
                    key={s.priceId}
                    isSelected={s.priceId === priceIdSelected}
                  >
                    <S.Type isSelected={s.priceId === priceIdSelected}>
                      {s.priceMetadata.type}
                    </S.Type>
                    <S.Price
                      isSelected={s.priceId === priceIdSelected}
                      style={{ marginTop: 16 }}
                    >
                      {getDisplayPrice(s.amount, s.currency)}
                    </S.Price>

                    <S.Discount isSelected={s.priceId === priceIdSelected}>
                      {s.priceMetadata.detail
                        ? `(${s.priceMetadata.detail})`
                        : ''}
                    </S.Discount>

                    <S.Details isSelected={s.priceId === priceIdSelected}>
                      {s.metadata.detailOne}
                    </S.Details>
                    <S.Details isSelected={s.priceId === priceIdSelected}>
                      {s.metadata.detailTwo}
                    </S.Details>
                    <S.Details isSelected={s.priceId === priceIdSelected}>
                      {s.metadata.detailThree}
                    </S.Details>
                    {s.priceMetadata?.detailOne && (
                      <S.Details isSelected={s.priceId === priceIdSelected}>
                        {s.priceMetadata.detailOne}
                      </S.Details>
                    )}
                    {s.priceMetadata?.detailTwo && (
                      <S.Details isSelected={s.priceId === priceIdSelected}>
                        {s.priceMetadata.detailTwo}
                      </S.Details>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <S.SignUpButton
                        isSelected={s.priceId === priceIdSelected}
                        onClick={() => {
                          handleSelection(s)
                          triggerAnalytics(s)
                        }}
                        data-test={`selectSubscriptionButton${index}`}
                      >
                        <S.SignUpText
                          isSelected={s.priceId === priceIdSelected}
                        >
                          {s.priceId === priceIdSelected
                            ? 'Selected'
                            : 'Select'}
                        </S.SignUpText>
                      </S.SignUpButton>
                    </div>
                  </S.SubscriptionContainer>
                ))}
              </S.Subscriptions>
            )
          }
        </MediaQuery>
      )}
    </S.SubscriptionWrapper>
  )
}

const mapStateToProps = (state: RootState) => ({
  subscriptions: state.entities.subscriptionsReducer.subscriptions,
})

const mapDispatchToProps = {
  onFetchSubscriptions: onFetchSubscriptionsAction.request,
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    priceIdSelected: string
    handleSelection: (subscription: SubscriptionResponse) => void
  }

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions)
